const useEmailVerifier = () => {
    const verify = (email, callback) => {
        fetch(
            `https://api.usercheck.com/email/${email}`, {
                'method'    : 'GET',
                'headers'   : {
                    'Authorization' : `Bearer NkhoCjaAzaBq4BZysfDojpMh7KXHtVtS`,
                },
            }
        ).then(
            resp => resp.json()
        ).then(
            json => callback && callback(
                json.status === 200 && json.mx && !json.disposable,
                !json.mx || json.disposable? "Invalid email address":
                json.error || ''
            )
        )
    }

    return verify
}

export default useEmailVerifier